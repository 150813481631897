import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	strapiAuthenticatedEndpoints,
	strapiPublicEndpoints,
} from "./endpoints";
import Navigation from "./navigation/Navigation";
import * as CmsActions from "./store/actions/cms";
import * as AuthActions from "./store/actions/auth";
import DoordashPopup from "./components/DoordashPopup";

function App() {
	// log our data to the console so that we can read it
	const dispatch = useDispatch();
	const { data } = useSelector((state) => state.cms);
	const { user, jwt } = useSelector((state) => state.auth);
	console.log({ ...data, jwt: jwt, user: user });

	const [doordashPopup, setDoordashPopup] = useState(true);

	// persist user and token from local storage
	// useEffect(() => {
	//   if (!user || !jwt) {
	//     let localUser = JSON.parse(localStorage.getItem("user"));
	//     let localToken = JSON.parse(localStorage.getItem("jwt"));
	//     if (localUser && localToken) dispatch(AuthActions.authenticate(localToken, localUser));
	//   }
	// }, []);

	// fetch all of our data on app load from Strapi.
	// To edit what endpoints are accessed, edit the ./endpoints.js file
	useEffect(() => {
		strapiPublicEndpoints.forEach((el) =>
			dispatch(CmsActions.fetchCms(el.slug, el.populate))
		);
	}, []);

	//useEffect(() => {
	//  if (jwt) strapiAuthenticatedEndpoints.forEach((el) => dispatch(CmsActions.fetchCms(el.slug, el.populate, jwt)));
	//}, [jwt]);

	// Display our app navigator component from ./navigation/Navigation.js
	return (
		<>
			<Navigation />
			<DoordashPopup open={doordashPopup} setIsOpen={setDoordashPopup}/>
		</>
	);
}

export default App;
