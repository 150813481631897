// This file defines the strapi endpoints you want to pull dtata from (collections & single types)
// This data will be fetched every time the page is loaded or refreshed

// slug = database table name, also known as API ID (Plural)
// fields = what columns from the table do you want to use?
// populate = how to show nested components & relations (doc: https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/populating-fields.html#relation-media-fields)
// There's two formats for doing this. Populate can be a string or qs.stringify()

import qs from "qs";

export const strapiPublicEndpoints = [
  // Single Types
  {
    slug: "navbar",
    fields: ["logo", "links", "homeButton", "menuButton", "aboutButton", "contactButton"],
    populate: "populate=*",
  },
  {
    slug: "footer",
    fields: ["pageLinks", "address", "phone", "Hours", "specialHours", "socialLinks", "logo"],
    populate: "populate=*",
    populate: qs.stringify({
      populate: {
        address: {
          populate: "*",
        },
        pageLinks: {
          populate: "*",
        },
        phone: {
          populate: "*",
        },
        Hours: {
          populate: "*",
        },
        specialHours: {
          populate: "*",
        },
        logo: {
          populate: "*",
        },
        socialLinks: {
          populate: ["icon"],
        },
      },
    }),
  },
  {
    slug: "landing-page",
    fields: ["hero", "menuButton", "heroImage", "infoSection", "infoSectionButton", "featuredHeading", "mapSection", "contactButton"],
    populate: "populate=*",
  },
  {
    slug: "menu-page",
    fields: ["categories"],
    populate: "populate=*",
  },

  {
    slug: "about-us",
    fields: ["heading", "AboutUsSectionOne", "AboutUsSectionTwo", "AboutUsSectionThree"],
    //populate: "populate=*",
    populate: qs.stringify({
      populate: {
        AboutUsSectionOne: {
          populate: "photo",
        },
        AboutUsSectionTwo: {
          populate: "photo",
        },
        AboutUsSectionThree: {
          populate: "photo",
        },
      },
    }),
  },

  {
    slug: "contact-us",
    fields: ["cards", "header", "contactFormHeader"],
    // populate: "populate=*",
    populate: qs.stringify({
      populate: {
        cards: {
          populate: "icon",
          populate: "text",
        },
        header: {
          populate: "*",
        },
        cards: {
          populate: "*",
        },
      },
    }),
  },

  // Collections
  {
    slug: "menu-items",
    fields: ["category", "isFeatured", "title", "description", "price", "photo"],
    populate: "populate=*",
  },
];

// An example for getting complicated with the populate feild:
//   populate: qs.stringify({
//     populate: {
//       exampleSlider: {
//         populate: {
//           items: {
//             populate: "background",
//           },
//         },
//       },
//       exampleAccordion: {
//         populate: ["items"],
//       },
//     },
//   }),
