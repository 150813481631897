import React, { useEffect } from "react";
import WebFont from "webfontloader";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

function Head() {
  const { id } = useParams();

  useEffect(() => {
    // If your head code includes any google fonts, place them here
    WebFont.load({
      google: {
        families: [
          "Alata:regular",
          "Quicksand:regular",
          "Arapey:regular,italic",
          "Open Sans:300,300italic,400,400italic,600,600italic,700,700italic,800,800italic",
          "Lemon:regular",
        ],
      },
    });
  }, []);

  //   Depending on the page we're on, we'll want different head tags. make a 'case' for each page slug,
  //   Convert your webflow <head></head> code to JSX and paste it between these <Helmet></Helmet> tags
  switch (window.location.pathname) {
    case "/":
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Fusión Latina</title>
          <meta
            content="A Restaurant that combines the cuisines of Argentina and Cuba to provide authentic homemade food bursting with flavour"
            name="description"
          />
          <meta content="Fusión Latina" property="og:title" />
          <meta
            content="A Restaurant that combines the cuisines of Argentina and Cuba to provide authentic homemade food bursting with flavour"
            property="og:description"
          />
          <meta content="Fusión Latina" property="twitter:title" />
          <meta
            content="A Restaurant that combines the cuisines of Argentina and Cuba to provide authentic homemade food bursting with flavour"
            property="twitter:description"
          />
          <meta property="og:type" content="website" />
          <meta content="summary_large_image" name="twitter:card" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link
            href="css/fusion-latina-7cc7cc530c7fecae068a00cbf.webflow.css"
            rel="stylesheet"
            type="text/css"
          />
          <style
            dangerouslySetInnerHTML={{
              __html:
                '@media (min-width:992px) {html.w-mod-js:not(.w-mod-ix) [data-w-id="403b5fcd-0b48-a33a-aef4-df50d8899b9c"] {-webkit-transform:translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform-style:preserve-3d;}}@media (max-width:991px) and (min-width:768px) {html.w-mod-js:not(.w-mod-ix) [data-w-id="403b5fcd-0b48-a33a-aef4-df50d8899b9c"] {-webkit-transform:translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform-style:preserve-3d;}}',
            }}
          />
          {/* [if lt IE 9]><![endif] */}
          <link
            href="images/favicon.png"
            rel="shortcut icon"
            type="image/x-icon"
          />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );

    case "/menu":
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Menu</title>
          <meta
            content="Our Menu is filled with all types of Food from sandwiches to sweets that are all homemade"
            name="description"
          />
          <meta content="Menu" property="og:title" />
          <meta
            content="Our Menu is filled with all types of Food from sandwiches to sweets that are all homemade"
            property="og:description"
          />
          <meta content="Menu" property="twitter:title" />
          <meta
            content="Our Menu is filled with all types of Food from sandwiches to sweets that are all homemade"
            property="twitter:description"
          />
          <meta property="og:type" content="website" />
          <meta content="summary_large_image" name="twitter:card" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link
            href="css/fusion-latina-7cc7cc530c7fecae068a00cbf.webflow.css"
            rel="stylesheet"
            type="text/css"
          />
          {/* [if lt IE 9]><![endif] */}
          <link
            href="images/favicon.png"
            rel="shortcut icon"
            type="image/x-icon"
          />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );

    case "/about":
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>About Us</title>
          <meta
            content="Fusion Latina is the blend between Argentinian and Cuban food brought together by a Husband and Wife who provide authentic homemade food bursting with flavour"
            name="description"
          />
          <meta content="About Us" property="og:title" />
          <meta
            content="Fusion Latina is the blend between Argentinian and Cuban food brought together by a Husband and Wife who provide authentic homemade food bursting with flavour"
            property="og:description"
          />
          <meta content="About Us" property="twitter:title" />
          <meta
            content="Fusion Latina is the blend between Argentinian and Cuban food brought together by a Husband and Wife who provide authentic homemade food bursting with flavour"
            property="twitter:description"
          />
          <meta property="og:type" content="website" />
          <meta content="summary_large_image" name="twitter:card" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link
            href="css/fusion-latina-7cc7cc530c7fecae068a00cbf.webflow.css"
            rel="stylesheet"
            type="text/css"
          />
          {/* [if lt IE 9]><![endif] */}
          <link
            href="images/favicon.png"
            rel="shortcut icon"
            type="image/x-icon"
          />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );

    case "/contact":
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Contact</title>
          <meta
            content="Contact us to find out about about our food and for information for caterings"
            name="description"
          />
          <meta content="Contact" property="og:title" />
          <meta
            content="Contact us to find out about about our food and for information for caterings"
            property="og:description"
          />
          <meta content="Contact" property="twitter:title" />
          <meta
            content="Contact us to find out about about our food and for information for caterings"
            property="twitter:description"
          />
          <meta property="og:type" content="website" />
          <meta content="summary_large_image" name="twitter:card" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link
            href="css/fusion-latina-7cc7cc530c7fecae068a00cbf.webflow.css"
            rel="stylesheet"
            type="text/css"
          />
          {/* [if lt IE 9]><![endif] */}
          <link
            href="images/favicon.png"
            rel="shortcut icon"
            type="image/x-icon"
          />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );

    default:
      return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>Not Found</title>
            <meta content="Not Found" property="og:title" />
            <meta content="Not Found" property="twitter:title" />
            <meta
              content="width=device-width, initial-scale=1"
              name="viewport"
            />
            <meta content="Webflow" name="generator" />
            <link href="css/normalize.css" rel="stylesheet" type="text/css" />
            <link href="css/webflow.css" rel="stylesheet" type="text/css" />
            <link
              href="css/fusion-latina-7cc7cc530c7fecae068a00cbf.webflow.css"
              rel="stylesheet"
              type="text/css"
            />
            {/* [if lt IE 9]><![endif] */}
            <link
              href="images/favicon.png"
              rel="shortcut icon"
              type="image/x-icon"
            />
            <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );
  }
}

export default Head;
