import React from "react";

export default function DoordashPopup({ open, setIsOpen }) {
	return (
		<>
			{open && (
				<div
					style={{
						position: "fixed",
						bottom: "16px",
						right: "16px",
						marginLeft: "16px",
						backgroundColor: "rgba(255,255,255,0.5)",
						backdropFilter: "blur(10px)",
						color: "red",
						zIndex: "999",
						padding: "12px 16px",
						borderRadius: "12px",
						display: "flex",
						fontSize: "15px",
						alignItems: "center",
						gap: "8px",
						fontFamily: "TT Norms",
						boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.0.5)",
					}}
				>
					<div style={{
						display: "flex",
						gap: "8px",
						alignItems: "stretch",
						justifyContent: "center",
					}}>
						<a href="https://www.doordash.com/en-CA/store/fusion-latina-lincoln-25089116/" style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							padding: "8px 16px",
							backgroundColor: "#fff",
							borderRadius: "8px",
							boxShadow: "0 0 10px 0 rgba(0,0,0,0.15)"
						}}>
							<img src="/images/online-ordering/doordash.png" alt="Doordash Logo" style={{
								width: "130px"
							}} />
						</a>
						<a href="https://www.skipthedishes.com/fusion-latina-king-street" style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							padding: "8px 16px",
							backgroundColor: "#fff",
							borderRadius: "8px",
							boxShadow: "0 0 10px 0 rgba(0,0,0,0.15)"
						}}>
							<img src="/images/online-ordering/skip.png" alt="Skip The Dishes Logo" style={{
								width: "130px"
							}} />
						</a>
						<a href="https://www.ubereats.com/ca/store/fusion-latina/BG2EtzbKRrGNVgoJjGzASw?diningMode=DELIVERY&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMkZ1c2lvbiUyMExhdGluYSUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMmE0Mzg4MTZmLWVhOTUtZDQ3NC0yYzU2LTM3YzM4NGExMDA5YiUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJ1YmVyX3BsYWNlcyUyMiUyQyUyMmxhdGl0dWRlJTIyJTNBNDMuMTY2NzIlMkMlMjJsb25naXR1ZGUlMjIlM0EtNzkuNDc4MDElN0Q%3D" style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							padding: "8px 16px",
							backgroundColor: "#fff",
							borderRadius: "8px",
							boxShadow: "0 0 10px 0 rgba(0,0,0,0.15)"
						}}>
							<img src="/images/online-ordering/ubereats.png" alt="Uber Eats Logo" style={{
								width: "130px"
							}} />
						</a>
					</div>

					{/* Close button */}
					<button
						style={{
							position: "absolute",
							top: "-4px",
							right: "-4px",
							width: "24px",
							height: "24px",
							backgroundColor: "rgba(230,230,230,1)",
							border: "none",
							borderRadius: "50%",
							color: "#333",
							cursor: "pointer",
							fontFamily: "TT Norms",
							fontSize: "18px",
						}}
						onClick={() => setIsOpen(false)}
					>
						&times;
					</button>
				</div>
			)}
		</>
	);
}
