import React from "react";

const LoadingPage = () => {
  return <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100vw", height: "90vh"}}>
     <div
        style={{ display: "flex", flexDirection:"column" }}
        className="preloader"

      >
        <div
          className="lottie-animation"
          data-w-id="04d28dff-4eae-83d5-0437-d81217cb3cf3"
          data-animation-type="lottie"
          data-src="https://uploads-ssl.webflow.com/635297a38f13cf979ac859a4/6352f6babcd4c632e72b2235_lf30_editor_e6q28jhk.json"
          data-loop={1}
          data-direction={1}
          data-autoplay={1}
          data-is-ix2-target={0}
          data-renderer="svg"
          data-default-duration="3.0166666666666666"
          data-duration={0}
          style={{maxWidth:"40vw" }}
        />
        <div 
        style={{textAlign:"center",fontSize:"32px", color:"#f9bb33" }}
        >
          Loading...
        </div>
      </div>
    </div>;
};

export default LoadingPage;
