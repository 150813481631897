import React from "react";
import Page from "../components/Page";

// Import all the functions we need
import { useSelector } from "react-redux";
import ErrorPage from "./ErrorPage";
import LoadingPage from "./LoadingPage";

function AboutUsPage() {
  const singleTypeSlug = "about-us";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;
  // only display the component if our data has been loaded
  if (!cmsData) return <LoadingPage />;
  return (
    <Page data-wf-page="6356b9ae34eda4676129e0c3" data-wf-site="6356b9ae34eda4d04629e0bf">
      <section className="section special wf-section">
        <div className="container w-container">
          <div className="w-layout-grid new_au_grid1">
            {cmsData?.AboutUsSectionOne && (
              <>
                <div id="w-node-ac8f71b0-7698-b19b-f9b0-eb795d3a9307-6129e0c3" className="div-block-19">
                  <h3 id="w-node-ac8f71b0-7698-b19b-f9b0-eb795d3a9308-6129e0c3" className="new_h3">
                    {cmsData?.AboutUsSectionOne?.heading}
                  </h3>
                  <p id="w-node-ac8f71b0-7698-b19b-f9b0-eb795d3a930a-6129e0c3" className="new_para">
                    {cmsData?.AboutUsSectionOne?.paragraph}
                  </p>
                </div>
                <div
                  id="w-node-ac8f71b0-7698-b19b-f9b0-eb795d3a930c-6129e0c3"
                  className="aboutusimagesdiv"
                  style={{
                    backgroundImage: `url(${cmsData?.AboutUsSectionOne?.photo?.data?.attributes?.url})`,
                  }}
                >
                  <div className="imageborderaboutus" />
                </div>
              </>
            )}
            {cmsData?.AboutUsSectionTwo && (
              <>
                <div
                  id="w-node-ac8f71b0-7698-b19b-f9b0-eb795d3a930f-6129e0c3"
                  className="aboutusimagesdiv"
                  style={{
                    backgroundImage: `url(${cmsData?.AboutUsSectionTwo?.photo?.data?.attributes?.url})`,
                  }}
                >
                  <div className="imageborderaboutus" />
                </div>
                <div id="w-node-ac8f71b0-7698-b19b-f9b0-eb795d3a9311-6129e0c3" className="special-div">
                  <h3 id="w-node-ac8f71b0-7698-b19b-f9b0-eb795d3a9312-6129e0c3" className="new_h3">
                    {cmsData?.AboutUsSectionTwo?.heading}
                  </h3>
                  <p id="w-node-ac8f71b0-7698-b19b-f9b0-eb795d3a9314-6129e0c3" className="new_para">
                    {cmsData?.AboutUsSectionTwo?.paragraph}
                  </p>
                </div>
              </>
            )}
            {cmsData?.AboutUsSectionThree && (
              <>
                <div id="w-node-ac8f71b0-7698-b19b-f9b0-eb795d3a9316-6129e0c3">
                  <h3 id="w-node-ac8f71b0-7698-b19b-f9b0-eb795d3a9317-6129e0c3" className="new_h3">
                    {cmsData?.AboutUsSectionThree?.heading}
                  </h3>
                  <p id="w-node-ac8f71b0-7698-b19b-f9b0-eb795d3a9319-6129e0c3" className="new_para">
                    {cmsData?.AboutUsSectionThree?.paragraph}
                  </p>
                </div>
                <div
                  id="w-node-ac8f71b0-7698-b19b-f9b0-eb795d3a931b-6129e0c3"
                  className="aboutusimagesdiv"
                  style={{
                    backgroundImage: `url(${cmsData?.AboutUsSectionThree?.photo?.data?.attributes?.url})`,
                  }}
                >
                  <div className="imageborderaboutus" />
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </Page>
  );
}

export default AboutUsPage;
