import React from "react";
import Page from "../components/Page";

// Import all the functions we need
import { useSelector } from "react-redux";
import { usePullData } from "../hooks/usePullData";
import { renderCollection } from "../util/renderCollection";
import ErrorPage from "./ErrorPage";
import LoadingPage from "./LoadingPage";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import MenuSliderLanding from "../components/MenuSliderLanding";
import FlagsSection from "../components/FlagsSection";

function IndexPage() {
  // access our data so that we can display it
  const singleTypeSlug = "landing-page";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  //Get featured items data
  const collectionSlug = "menu-items";
  const collectionData = data[collectionSlug];

  // only display the component if our data has been loaded
  if (!cmsData || !collectionData) return <LoadingPage />;

  return (
    <Page data-wf-page="6356b9ae34eda4506929e0c0" data-wf-site="6356b9ae34eda4d04629e0bf">
      {/* <div data-w-id="04d28dff-4eae-83d5-0437-d81217cb3cf2" style={{ display: "flex" }} className="preloader">
        <div
          className="lottie-animation"
          data-w-id="04d28dff-4eae-83d5-0437-d81217cb3cf3"
          data-animation-type="lottie"
          data-src="https://uploads-ssl.webflow.com/635297a38f13cf979ac859a4/6352f6babcd4c632e72b2235_lf30_editor_e6q28jhk.json"
          data-loop={0}
          data-direction={1}
          data-autoplay={1}
          data-is-ix2-target={0}
          data-renderer="svg"
          data-default-duration="3.0166666666666666"
          data-duration={0}
        />
        <div className="text-block-12">Loading...</div>
      </div> */}
      <section
        className="hero wf-section"
        style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url("${cmsData.heroImage.data.attributes.url}")` }}
      >
        <div className="hero-container w-container">
          {/* <h1 className="hero-header">FUSIÓN LATINA</h1> */}
          <h1 className="hero-header">{cmsData.hero.companyName}</h1>
          <div className="slogandiv">
            <div id="w-node-_2c98afa1-b497-2644-d2e6-f9f3d0b8065b-6929e0c0" className="sloganline" />
            <div className="slogan">{cmsData.hero.slogan}</div>
            <div id="w-node-_4c8144cf-ed22-2954-e98d-4f19c430d040-6929e0c0" className="sloganline" />
          </div>
          <Link Link key={uuid()} to={cmsData.menuButton.slug} className="contactbutton-copy w-button">
            {cmsData.menuButton.text}
          </Link>
          <Link to="/menu" className="hero-button-flag w-button">
            Menu
          </Link>
        </div>
      </section>
      <FlagsSection content={cmsData.infoSection} />

      <section className="section wf-section">
        <div className="featuredlandingdiv">
          <h2 className="featured-header">{cmsData.featuredHeading}</h2>
          <div className="line" />
        </div>
        <div className="featured-container w-container">
          <MenuSliderLanding data={collectionData} />
        </div>
      </section>
      <section className="section wf-section">
        <div className="featured-container w-container">
          <div className="w-layout-grid grid-4">
            <div id="w-node-_78f6e91a-35f1-db21-77d1-6de4fc35c04a-6929e0c0" className="addressdivlanding">
              <h3 className="heading-7">{cmsData.mapSection.mapHeading}</h3>
              <div className="smallline" />
              <div className="addresslanding">{cmsData.mapSection.address}</div>
              {/* <Link Link key={uuid()} to={cmsData.contactButton.slug} className="contactbutton w-button">
                {cmsData.contactButton.text}
              </Link> */}
            </div>
            <div
              className="map-2 w-widget w-widget-map"
              data-widget-style="roadmap"
              data-widget-latlng="43.16680239999999,-79.477727"
              aria-label=""
              data-enable-scroll="true"
              role="region"
              title=""
              data-enable-touch="true"
              data-widget-zoom={13}
              id="w-node-_149fb6c3-5fa2-b941-4a41-73f47ab66a00-6929e0c0"
              data-widget-tooltip=""
            />
          </div>
        </div>
      </section>
    </Page>
  );
}

export default IndexPage;

const CollectionEntryFeatured = (props) => {
  let data = usePullData(props);
  if (!data.isFeatured) return null;
  return (
    <div
      className="slide w-slide"
      style={{
        backgroundImage: `url(${data.photo.data && data.photo.data.attributes.url})`,
      }}
    >
      <div className="landingfeatureddiv">
        <div className="featuredcarddiv-2">
          <h3 className="featuredcardh3">{data.title}</h3>
          <p className="featuredcardparagraph">{data.description}</p>
        </div>
        <div className="featuredprice">{data.price}</div>
      </div>
    </div>
  );
};
