import React from "react";
import Page from "../components/Page";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function ErrorPage() {
  return (
    <Page data-wf-page="6356b9ae34eda406c429e0c2" data-wf-site="6356b9ae34eda4d04629e0bf">
      <section className="wf-section">
        <div className="container-3 w-container">
          <img
            src="images/404-Error-Page-not-Found-with-people-connecting-a-plug-amico.svg"
            loading="lazy"
            alt=""
            className="image-6"
          />
          <Link to="/" className="_404-button w-button">
            Home
          </Link>
        </div>
      </section>
    </Page>
  );
}

export default ErrorPage;
