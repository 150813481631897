// Our Footer component
// Thsi will need to be modified to match the JSX coming from webflow

import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";

function Footer() {
  // access our data so that we can use it
  const { data } = useSelector((state) => state.cms);
  const singleTypeSlug = "footer";
  const footerData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  if (!footerData) return null;

  return(<div className="footer wf-section" style={{marginTop: "64px"}}>
  <div className="footer-container w-container">
    <div className="w-layout-grid footer-grid">
      <div
        id="w-node-a17eefa2-54b8-f236-eae9-fff5671f4850-0422a1cf"
        className="footer-div"
      >
        <h3 className="footer-heading">Address</h3>
        <div className="footer-text-2">
          {/* 5041 Kings Street, <br />
          Unit 3, Beamsville, <br />
          ON L0R 1B8 */}
          <div className="display-linebreak">{footerData.address}</div>
        </div>
      </div>
      <div
        id="w-node-a17eefa2-54b8-f236-eae9-fff5671f4855-0422a1cf"
        className="footer-div-copy"
      >
        <img
          src="images/edit.png"
          loading="lazy"
          width={164}
          srcSet="images/edit-p-500.png 500w, images/edit.png 570w"
          sizes="164px"
          alt=""
          className="image-7"
        />
      </div>
      <div
        id="w-node-a17eefa2-54b8-f236-eae9-fff5671f4857-0422a1cf"
        className="footer-div"
      >
        <h3 className="footer-heading">Phone</h3>
        <div className="footer-text-2"> {footerData.phone}</div>
      </div>
      <div
        id="w-node-a17eefa2-54b8-f236-eae9-fff5671f485c-0422a1cf"
        className="line"
      />
      <div
        id="w-node-a17eefa2-54b8-f236-eae9-fff5671f485d-0422a1cf"
        className="div-block-2"
      >
        <h3 className="footer-heading">Pages</h3>
        {footerData.pageLinks &&
              footerData.pageLinks.map((item) => {
                return (
        <Link Link
        key={uuid()}
        to={item.slug} className="footer-link-2">
          {item.text}
        </Link>
          );
        })}
      </div>
      <div id="w-node-a17eefa2-54b8-f236-eae9-fff5671f4868-0422a1cf">
        <h3 className="footer-heading">Hours</h3>
        {footerData.Hours &&
              footerData.Hours.map((item) => {
                return (
        <div className="footer-text-2">
          {/* Mon-Fri: 11am-7pm
          <br />
          Sat: 12pm-6pm
          <br />
          Sun: Closed */}
          {item.theHours}
          
        </div>
         );
        })}
        <h3 className="footer-subheading">Special Hours</h3>
        {footerData.specialHours &&
              footerData.specialHours.map((item) => {
                return (
        <div className="text-block-13">{item.theHours}</div>
        );
        })}
      </div>
      <div
        id="w-node-a17eefa2-54b8-f236-eae9-fff5671f4875-0422a1cf"
        className="div-block-3"
      >
        <h3 className="footer-heading">Socials</h3>
        <div className="w-layout-grid grid">
        {footerData.socialLinks &&
              footerData.socialLinks.map((item) => {
                return (
          <a
            id="w-node-a17eefa2-54b8-f236-eae9-fff5671f4879-0422a1cf"
            href={item.slug}
            target="_blank"
            className="social-link-block w-inline-block"
          >
            <img
            //  src="images/facebook.png"
              src={item.icon.data && item.icon.data.attributes.url}
              loading="lazy"
              sizes="(max-width: 479px) 25vw, (max-width: 767px) 13vw, (max-width: 991px) 100.1953125px, (max-width: 1279px) 132px, 176px"
              alt=""
              className="social-icon"
            />
          </a>
          );
        })}
          {/* <a
            id="w-node-a17eefa2-54b8-f236-eae9-fff5671f4879-0422a1cf"
            href="https://www.facebook.com/people/Fusi%C3%B3n-Latina/100083341508847/"
            target="_blank"
            className="social-link-block w-inline-block"
          >
            <img
              src="images/facebook.png"
              loading="lazy"
              srcSet="images/facebook-p-500.png 500w, images/facebook.png 512w"
              sizes="(max-width: 479px) 25vw, (max-width: 767px) 13vw, (max-width: 991px) 100.1953125px, (max-width: 1279px) 132px, 176px"
              alt=""
              className="social-icon"
            />
          </a>
          <a
            id="w-node-a17eefa2-54b8-f236-eae9-fff5671f487b-0422a1cf"
            href="https://www.instagram.com/"
            target="_blank"
            className="social-link-block-copy w-inline-block"
          >
            <img
              src="images/instagram.png"
              loading="lazy"
              srcSet="images/instagram-p-500.png 500w, images/instagram.png 512w"
              sizes="(max-width: 479px) 25vw, (max-width: 767px) 13vw, (max-width: 991px) 100.1953125px, (max-width: 1279px) 132px, 176px"
              alt=""
              className="social-icon"
            />
          </a> */}
        </div>
      </div>
    </div>
    <a
      href="https://www.civiconnect.ca/"
      target="_blank"
      className="link-block w-inline-block"
    >
      <img
        src="images/White-Ver..svg"
        loading="lazy"
        alt=""
        className="image-8"
      />
    </a>
  </div>
</div>
)

  // return (
  //   <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", backgroundColor: "grey" }}>
  //     {/* loop through our nav links and display them one by one */}
  //     {footerData.links &&
  //       footerData.links.map((item) => {
  //         return (
  //           <Link key={uuid()} to={item.slug}>
  //             {item.text}
  //           </Link>
  //         );
  //       })}
  //   </div>
  // );
}

export default Footer;
