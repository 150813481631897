// Starting point of our react application
// This is the parent file, everything else is called by this
import React from "react";
import ReactDOM from "react-dom/client";
import "./css/App.css";
import App from "./App";
import { Provider } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import authReducer from "./store/reducers/auth";
import cmsReducer from "./store/reducers/cms";
import shopReducer from "./store/reducers/shop";
import lightboxReducer from "./store/reducers/lightbox";
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

// Setting up our Redux store.
// This is where all of our apps state variables are stored.
// All data from Strapi is loaded into the redux store
const rootReducer = combineReducers({
	auth: authReducer,
	cms: cmsReducer,
	shop: shopReducer,
	lightbox: lightboxReducer,
});
const store = configureStore({ reducer: rootReducer });

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
	"pk_test_51LttzdAl0i78auXX5Q1TeaWOaYkvSbYqRbTUAdW6g0L79E5J9me430rn8A5EWxF0zOXh5SsAOiDw5HWT28OOeuwD00VF8iTu8d"
);
const options = {
	// passing the client secret obtained from the server
	// clientSecret: "{{CLIENT_SECRET}}",
};
// Render our application into public/index.html at the #root component
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Elements stripe={stripePromise} options={options}>
		<Provider store={store}>
			<link
				href="https://fonts.cdnfonts.com/css/tt-norms"
				rel="stylesheet"
			></link>
			<App />
		</Provider>
	</Elements>
);
