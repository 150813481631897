import React, { useState } from "react";
import "../css/MenuDropdown.css";
import Page from "../components/Page";
const MenuDropdown = ({ selectedCategory, setSelectedCategory, categories }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="dropdown w-dropdown">
      <div className={`dropdown-toggle w-dropdown-toggle ${isOpen && "dd_button_hide"}`} onClick={() => setIsOpen(true)}>
        <div className="dd_icon" />
        <div className="dd_current">{selectedCategory}</div>
      </div>
      <nav className={`dropdown-list w-dropdown-list ${isOpen && "dd_open"}`} onClick={() => setIsOpen(false)}>
        <a href="#" onClick={() => setSelectedCategory("All")} className="dropdown-menu w-dropdown-link">
          All
        </a>
        {categories &&
          categories.map((el) => {
            return (
              <a href="#" onClick={() => setSelectedCategory(el.value)} className="dropdown-menu w-dropdown-link">
                {el.value}
              </a>
            );
          })}
      </nav>
    </div>
  );
};

export default MenuDropdown;
