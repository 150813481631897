// Weblfow Sliders tend not to work well once exported
// This allows us to have a Strapi CMS linked Slider
// May need some modificatons to match your webflow code structure
import React from "react";

const MenuSliderLanding = ({ data }) => {
  return (
    <div
      data-delay={4000}
      data-animation="slide"
      className="slider w-slider"
      data-autoplay="true"
      data-easing="ease"
      data-hide-arrows="false"
      data-disable-swipe="false"
      data-autoplay-limit={0}
      data-nav-spacing={3}
      data-duration={1000}
      data-infinite="true"
      
    >
      <div className="mask w-slider-mask" >
        {data.map((el) => {
          return <Slide data={el.attributes} />;
        })}
      </div>
      <div className="w-slider-arrow-left"  ></div>
      <div className="w-slider-arrow-right"  ></div>
      <div className="w-slider-nav w-round"  />
    </div>
  );
};

export const Slide = ({ data }) => {
  if (!data.isFeatured) return null;
  return (
    <div
      className="slide w-slide"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(${data.photo && data.photo.data && data.photo.data.attributes.url})`
      }}
    >
      <div className="landingfeatureddiv" >
        <div className="featuredcarddiv-2">
          <h1 className="featuredcardh3" >{data.title}</h1>
          <p className="featuredcardparagraph" >{data.description}</p>
        </div>
        <div className="featuredprice" >{data.price}</div>
      </div>
    </div>
  );
};

export default MenuSliderLanding;
