import React from "react";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";


const FlagsSection = ({ content }) => {

  return (
    <div className="section-2 wf-section">
      <div className="container-4 w-container">
        <div className="div-block-30">
          <div className="text-block-16">{content.words && content.words.split(" ")[0]}</div>
          <img
            src="images/1-28.png"
            loading="lazy"
            srcSet="images/1-28-p-500.png 500w, images/1-28-p-1080.png 1080w, images/1-28.png 1080w"
            sizes="(max-width: 479px) 100vw, 200px"
            alt=""
            className="image-9"
          />
          <div className="text-block-16 cuba">{content.words && content.words.split(" ")[1]}</div>
        </div>
        <p className="paragraph">{content.paragraph}</p>
        <Link  to="/about" className="contactbutton flagssection w-button">
          About Us
        </Link>
      </div>
    </div>
  );
};

export default FlagsSection;
