import React from "react";
import Page from "../components/Page";

// Import all the functions we need
import { useSelector } from "react-redux";
import ErrorPage from "./ErrorPage";
import LoadingPage from "./LoadingPage";

// your page that has the form must include this import line.
// This is a function that submits whatever data is in your form to strapi,
// where it is stored in the database and emailed out to all notification recipients

import { useSubmitForm } from "../hooks/useSubmitForm";

// Each input in your form must have a unique name attribute e.x. name="Email"
// your form tag must have the onSubmit={useSubmitForm} attribute
function ContactPage() {
  const { handleSubmit } = useSubmitForm();
  const singleTypeSlug = "contact-us";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded

  if (!cmsData) return <LoadingPage />;
  return (
    <Page data-wf-page="6356b9ae34eda436e929e0c4" data-wf-site="6356b9ae34eda4d04629e0bf">
      <section className="section wf-section">
        <div className="centered_container w-container">
          <h2>{cmsData.header}</h2>
          <div className="w-layout-grid contact-grid">
            {cmsData.cards &&
              cmsData.cards.map((item) => {
                return (
                  <div id="w-node-_382acddb-9b62-27b2-d5dd-ad0776de8b16-e929e0c4" className="contact-div">
                    <img
                      // src="images/address.png"
                      src={item.icon.data && item.icon.data.attributes.url}
                      loading="lazy"
                      id="w-node-_3964df34-b5bb-2e5e-0460-d1d86b78619f-e929e0c4"
                      // srcSet="images/address-p-500.png 500w, images/address.png 512w"
                      // sizes="(max-width: 479px) 44.265625px, (max-width: 1279px) 70px, (max-width: 1439px) 5vw, 70px"
                      alt=""
                      className="contact-icon"
                    />
                    <div className="contact-text">
                      <div className="display-linebreak">{item.text}</div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div
            className="map-3 w-widget w-widget-map"
            data-widget-style="roadmap"
            data-widget-latlng="43.16680239999999,-79.477727"
            aria-label=""
            data-enable-scroll="true"
            role="region"
            title=""
            data-enable-touch="true"
            data-widget-zoom={13}
            data-widget-tooltip=""
          />
        </div>
      </section>
      {/* <section className="form-section wf-section">
        <div className="centered_container w-container">
          <h2>{cmsData.contactFormHeader}</h2>
          <div className="form-block w-form">
            <form onSubmit={handleSubmit} id="email-form" name="email-form" data-name="Email Form" method="get" className="form">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input type="text" className="text-field w-input" maxLength={256} name="name" data-name="Name" placeholder="John Doe" id="name" required />
              <label htmlFor="email" className="form-label">
                Email Address
              </label>
              <input type="email" className="text-field w-input" maxLength={256} name="email" data-name="Email" placeholder="Johndoe@email.com" id="email" required />
              <label htmlFor="Phone" className="form-label">
                Phone Number
              </label>
              <input type="tel" className="text-field w-input" maxLength={256} minLength={10} name="Phone" data-name="Phone" placeholder="123-456-7890" id="Phone" required />
              <label htmlFor="Plain" className="form-label">
                Message
              </label>
              <textarea type="text" className="text-field big w-input" maxLength={256} name="Plain" data-name="Plain" placeholder="Leave a Message Here!" id="Plain" required />
              <input type="submit" defaultValue="Submit" data-wait="Please wait..." className="submit-button w-button" />
            </form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
      </section> */}
    </Page>
  );
}

export default ContactPage;
