// Our Navbar component
// This will need to be modified to match the JSX coming from webflow

import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";

function Navbar() {
  // access our data so that we can use it
  const { data } = useSelector((state) => state.cms);
  const singleTypeSlug = "navbar";
  const navData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // only display the component if our data has been loaded
  if (!navData) return null;

  return (
    <div data-animation="default" data-collapse="small" data-duration={400} data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
      <div className="nav-container w-container">
        <Link Link key={uuid()} to={navData.homeButton.slug} className="brand w-nav-brand">
          <img src={navData.logo.data && navData.logo.data.attributes.url} loading="lazy" sizes="(max-width: 767px) 130px, 100vw" alt="" className="logo" />
        </Link>
        <nav role="navigation" className="nav-menu w-nav-menu">
          <div className="nav-flex">
            <Link Link key={uuid()} to={navData.homeButton.slug} aria-current="page" className="nav-link w-nav-link w--current">
              {navData.homeButton.text}
            </Link>
            <Link Link key={uuid()} to={navData.menuButton.slug} className="nav-link w-nav-link">
              {navData.menuButton.text}
            </Link>
          </div>
          <Link Link key={uuid()} to={navData.homeButton.slug} className="brand-2 w-nav-brand">
            <img src={navData.logo.data && navData.logo.data.attributes.url} loading="lazy" width="Auto" sizes="(max-width: 767px) 100vw, 130px" alt="" className="logo" />
          </Link>
          <div className="nav-flex">
            <Link Link key={uuid()} to={navData.aboutButton.slug} className="nav-link w-nav-link">
              {navData.aboutButton.text}
            </Link>
            <Link Link key={uuid()} to={navData.contactButton.slug} className="nav-link w-nav-link">
              {navData.contactButton.text}
            </Link>
          </div>
        </nav>
        <div className="menu-button w-nav-button">
          <div className="icon w-icon-nav-menu" />
        </div>
      </div>
    </div>
  );

  // return (
  //   <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", backgroundColor: "grey" }}>
  //     {/* Display our navbar logo */}
  //     <img src={navData.logo.data && navData.logo.data.attributes.url} style={{ width: 200 }} />

  //     {/* loop through our nav links and display them one by one */}
  //     {navData.links &&
  //       navData.links.map((item) => {
  //         return (
  //           <Link key={uuid()} to={item.slug}>
  //             {item.text}
  //           </Link>
  //         );
  //       })}
  //   </div>
  // );
}

export default Navbar;
