import React, { useEffect, useState } from "react";
import Page from "../components/Page";

// Import all the functions we need
import { useSelector } from "react-redux";
import { usePullData } from "../hooks/usePullData";
import { renderCollection } from "../util/renderCollection";
import LoadingPage from "./LoadingPage";
import MenuSlider from "../components/MenuSlider";
import MenuDropdown from "../components/MenuDropdown";

function MenuPage() {
  const { data } = useSelector((state) => state.cms);

  // keep track of specific items to show depending on selected category
  const [filteredItems, setFilteredItems] = useState([]);
  // keep track of which category is currently selected
  const [selectedCategory, setSelectedCategory] = useState("All");

  const singleTypeSlug = "menu-page";
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  const collectionSlug = "menu-items";
  const collectionData = data[collectionSlug];

  // filter our data based on what category is selected
  useEffect(() => {
    if (collectionData) {
      if (selectedCategory === "All") {
        let tmp = [...collectionData].sort((a, b) => a.attributes.category.localeCompare(b.attributes.category));
        setFilteredItems(tmp);
      } else {
        let tmp = collectionData.filter((el) => el.attributes.category === selectedCategory);
        setFilteredItems(tmp);
      }
    }
  }, [selectedCategory]);

  // on page load sort collection data by category
  useEffect(() => {
    if (collectionData) {
      let tmp = [...collectionData].sort((a, b) => a.attributes.category.localeCompare(b.attributes.category));
      setFilteredItems(tmp);
    }
  }, [collectionData]);

  if (!collectionData || !cmsData) return <LoadingPage />;

  // build list of categories for our filter list
  let categories = [];
  collectionData.map((el) => {
    if (!categories.includes(el.attributes.category)) categories.push(el.attributes.category);
  });

  return (
    <Page data-wf-page="6356b9ae34eda4198e29e0c5" data-wf-site="6356b9ae34eda4d04629e0bf">
      <section className="featuredsection wf-section">
        {/* <h2 className="centered_heading">Featured Dishes</h2> */}
        <MenuSlider data={collectionData} />
      </section>

      <section className="section wf-section">
        <div className="centered_container w-container">
          <h2 className="centered_heading">Menu</h2>
          <p className="new_para-copy">
            <a href="tel:905-563-7494" className="call_link">
              Call ahead
            </a>{" "}
            to place your order
          </p>
          <a href="#" onClick={() => setSelectedCategory("All")} className="menucategory menucategoryall">
            Show All
          </a>
          <div className="menulinks">
            {cmsData.categories &&
              cmsData.categories.map((el) => {
                return (
                  <a href="#" onClick={() => setSelectedCategory(el.value)} className="menucategory">
                    {el.value}
                  </a>
                );
              })}
          </div>
          <div data-hover="false" data-delay={0} className="dropdown w-dropdown">
            <MenuDropdown categories={cmsData.categories} setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory} />
          </div>
          <div className="menucards">{renderCollection(CollectionEntry, filteredItems, collectionSlug)}</div>
        </div>
      </section>
    </Page>
  );
}

export default MenuPage;

const CollectionEntry = (props) => {
  let data = usePullData(props);
  return (
    <div
      id="w-node-de25a63e-d9db-e405-9b26-4f3b12ecc80b-8e29e0c5"
      className="carddiv"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(${data.photo.data && data.photo.data.attributes.url})`,
      }}
    >
      <h3 className="menuheading">{data.title}</h3>
      <p className="menuparagraph">{data.description}</p>
      <div>
        <div className="menudietaryrestrictions">
          <div className="menuprice">{data.price}</div>
        </div>
      </div>
    </div>
  );
};
